<template>
   <div>
      <div v-if="selectedSession" class="mb-6 flex items-center gap-4">
         <span class="text-gray-700">
            {{ selectedSession?.client?.name }}
         </span>
         <div class="flex gap-4">
            <CommonDateAndTimeView
               v-if="selectedSession?.date"
               :date="`${selectedSession?.date}T${selectedSession.time}`"
            ></CommonDateAndTimeView>
         </div>
      </div>
      <div
         v-if="!fromBookingPage"
         class="flex gap-4 cursor-pointer border-b border-b-gray-25"
      >
         <div
            @click="selectedOption = 'available'"
            :class="
               selectedOption === 'available' ?
                  'border-b-2  font-semibold border-primary-500 text-primary-500 pb-3 w-fit'
               :  ''
            "
            class="text-sm text-gray-700 cursor-pointer text-nowrap min-w-20"
         >
            {{ $t('sessions.Available') }}
         </div>
         <div
            @click="selectedOption = 'block'"
            :class="
               selectedOption === 'block' ?
                  'border-b-2  font-semibold border-primary-500 text-primary-500 pb-3 w-fit'
               :  ''
            "
            class="text-sm text-gray-700 cursor-pointer text-nowrap min-w-20"
         >
            {{ $t('sessions.Block') }}
         </div>
      </div>
      <div>
         <div v-if="!fromBookingPage" class="text-xs text-gray-200 mt-3">
            <p v-if="selectedOption === 'available'">
               Select Time and Date to add a new custom sessions beyond your
               Schedule Working Hours. <br class="hidden sm:block" />
               For example if you want to add night sessions on specific dates
            </p>
            <p v-else>
               {{ $t('sessions.blockDescription') }}
            </p>
         </div>
         <div v-else>
            <p v-if="selectedSession" class="text-xs text-gray-200 font-medium">
               Select new date and time to reschedule the session with your
               client
            </p>
            <p v-else class="text-xs text-gray-200 font-medium">
               Select a client, then select date and time slot to schedule a
               session on behalf of your client
            </p>
         </div>
         <div
            class="flex flex-col md:flex-row w-full space-y-3 md:space-y-0 md:space-x-5 mt-3"
         >
            <div
               v-if="fromBookingPage && !selectedSession"
               class="flex-1 md:border-e md:border-e-gray-25 md:pe-5"
            >
               <p class="pt-4 text-black font-medium text-sm">Assign Client</p>
               <p class="pt-3 text-gray-200 text-xs">
                  Search client or select it form your last active clients list
                  dropdown
               </p>
               <BaseSelect
                  height="min-h-10"
                  :options="clientList"
                  v-model:selected="clientId"
                  placeholder="Search by name or username"
                  @handleQuery="handleQuery"
               />
            </div>
            <div class="flex-1">
               <p class="py-4 text-black font-medium text-sm">Select Date</p>
               <CommonSimpleCalendar
                  class="col-span-12 md:col-span-5"
                  @selected="selectedDate = $event"
                  mode="date"
                  :value="
                     selectedSession ? selectedSession?.date : selectedDate
                  "
                  expand
               ></CommonSimpleCalendar>
               <template v-if="selectedDate">
                  <p class="py-4 text-black font-medium text-sm">
                     Select Timeslot
                  </p>
                  <div class="flex justify-center mb-4">
                     <CommonTimeInterval
                        v-model:start="time.start_time"
                        v-model:end="time.end_time"
                        @start-time="setStartTime"
                        hide-action
                        :disable-end="fromBookingPage"
                        :date="selectedDate"
                     ></CommonTimeInterval>
                  </div>
               </template>

               <div v-if="error" class="my-2">
                  <CommonAlerts
                     :outline="true"
                     type="error"
                     color="error"
                     :title="error"
                  ></CommonAlerts>
               </div>
            </div>
         </div>
         <div class="flex flex-col gap-3 py-3">
            <div class="flex gap-3 items-center" v-if="showConfirmation">
               <div
                  @click="haveConfirmed = !haveConfirmed"
                  v-if="!haveConfirmed"
                  class="h-4 w-4 border rounded-sm shrink-0"
               ></div>
               <div
                  v-else
                  class="h-4 w-4 rounded-sm bg-secondary-500"
                  @click="haveConfirmed = !haveConfirmed"
               >
                  <CheckIcon class="h-4 w-4 font-medium text-white"></CheckIcon>
               </div>
               <div class="text-base font-medium text-black">
                  I confirm that I have my client’s permission to book a session
                  on their behalf.
               </div>
            </div>
            <div
               class="flex flex-col md:flex-row w-full space-y-3 md:space-y-0 md:space-x-5"
            >
               <div class="flex-1"></div>
               <div class="flex-1">
                  <BaseButton
                     v-if="fromBookingPage"
                     @click.prevent="confirmAction"
                     :disabled="
                        !time.start_time ||
                        !time.end_time ||
                        (selectedSession ? false : !clientId) ||
                        !haveConfirmed
                     "
                     :loading="loading || busy"
                     class="w-full"
                  >
                     <span>
                        {{ selectedSession ? 'Reschedule' : 'Book' }}
                     </span>
                  </BaseButton>
                  <BaseButton
                     v-else
                     @click.prevent="confirmAction"
                     :disabled="
                        !time.start_time || !time.end_time || !haveConfirmed
                     "
                     :loading="loading || busy"
                     class="w-full"
                  >
                     Confirm
                  </BaseButton>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import { useTherapistApi } from '@/composables/therapistApi';
   import {
      ArrowRightIcon,
      CalendarIcon,
      CheckIcon,
      ClockIcon,
      XCircleIcon,
   } from '@heroicons/vue/24/outline';
   import dayjs from 'dayjs';
   import { mapActions, mapState } from 'pinia';
   import apiRoutes from '~/const/apiRoutes';
   export default {
      components: {
         XCircleIcon,
         CalendarIcon,
         ClockIcon,
         ArrowRightIcon,
         CheckIcon,
      },
      props: {
         fromBookingPage: {
            type: Boolean,
            default: false,
         },
         selectedSession: {
            type: Object,
            default() {
               return null;
            },
         },
         showConfirmation: {
            type: Boolean,
            default: false,
         },
      },
      data() {
         return {
            clientList: [
               { label: 'Dipesh Basnet', id: 1 },
               { label: 'Omar Massad', id: 2 },
            ],
            selectedOption: '',
            time: {
               start_time: '',
               end_time: '',
            },
            selectedDate: '',
            error: null,
            clientId: null,
            busy: false,
            error: null,
            errorMessages: null,
            loading: false,
            haveConfirmed: false,
         };
      },

      computed: {
         ...mapState(useTimeZoneStore, ['currentClientTimezone']),
         ...mapState(useTherapistAvailabilityStore, {
            initialDate: (state) => state.selectedDate,
         }),
      },
      created() {
         this.haveConfirmed = !this.showConfirmation;
         if (this.fromBookingPage) {
            this.getClientList();
         }
         if (!this.fromBookingPage && !this.selectedSession) {
            this.selectedOption = 'available';
         }
         if (this.selectedSession?.id) {
            this.time.start_time = this.$dayjs(
               this.$useTimeFormat(
                  this.$dayjs.utc(
                     this.selectedSession?.date +
                        'T' +
                        this.selectedSession?.time
                  )
               ),
               'hh:mm A'
            ).format('HH:mm');
            this.time.end_time = this.$dayjs(
               this.$useTimeFormat(
                  this.$dayjs
                     .utc(
                        this.selectedSession?.date +
                           'T' +
                           this.selectedSession?.time
                     )
                     .add(50, 'm')
               ),
               'hh:mm A'
            ).format('HH:mm');
         } else {
            if (this.initialDate)
               this.selectedDate = dayjs(this.initialDate).format('YYYY-MM-DD');
            else this.selectedDate = new Date().toISOString().split('T')[0];
            this.time.start_time = this.$dayjs(
               this.$useTimeFormat(this.$dayjs.utc(new Date())),
               'hh:mm A'
            ).format('HH:mm');
            this.setStartTime(this.time.start_time);
         }
      },
      methods: {
         ...mapActions(useSnackbarStore, ['setSnackbarDetail']),
         ...mapActions(useTherapistInfoStore, ['setReloadValue']),
         selectUser(detail) {
            this.clientId = detail.id;
         },
         handleQuery(query) {
            this.getClientList(query);
         },
         setStartTime(time) {
            const date =
               this.selectedSession?.date ?
                  this.selectedSession?.date
               :  this.selectedDate;
            this.time.start_time = time;
            this.time.end_time = this.$dayjs(
               this.$useTimeFormat(this.$dayjs(date + 'T' + time).add(50, 'm')),
               'hh:mm A'
            ).format('HH:mm');
         },
         confirmAction() {
            if (this.selectedSession?.id) {
               this.rescheduleSession();
            } else {
               if (this.fromBookingPage) {
                  this.bookSessionForClient();
               } else {
                  this.addNewInterval();
               }
            }
         },

         async bookSessionForClient() {
            this.errorMessages = null;
            if (this.loading) return;
            this.loading = true;

            let { data, error } = await useTherapistApi(
               apiRoutes?.therapist?.sessions?.book,
               {
                  method: 'POST',
                  body: {
                     date: this.selectedDate,
                     time: this.time?.start_time,
                     client_id: this.clientId || '',
                  },
               }
            );
            if (data?.value) {
               this.loading = false;
               this.$emit('success');
               this.$emit('close');
               this.setSnackbarDetail({
                  type: 'message',
                  title: data?.value?.message,
                  show: true,
               });
            }
            if (error?.value) {
               this.loading = false;
               this.error = error?.value?.data?.message;
               this.errorMessages = error?.value?.data?.message;
               this.setSnackbarDetail({
                  type: 'error',
                  title: error?.value?.data?.message,
                  show: true,
               });
            }
            this.loading = false;
         },
         async getClientList(searchValue) {
            if (this.loading) return;
            this.loading = false;
            let { data, error } = await useTherapistApi(
               apiRoutes?.therapist?.clientSearch,
               {
                  method: 'GET',
                  query: {
                     name: searchValue,
                  },
               }
            );
            if (data?.value) {
               this.clientList = data?.value?.map((item) => {
                  return {
                     name: item.name,
                     id: item.id,
                  };
               });

               this.loading = false;
            }
            if (error?.value) {
               this.loading = false;
            }
         },
         async rescheduleSession() {
            if (this.busy) return;
            this.busy = true;
            let { data, error } = await useTherapistApi(
               apiRoutes?.therapist?.sessions?.reschedule(
                  this.selectedSession?.id
               ),
               {
                  method: 'POST',
                  body: {
                     date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
                     time: dayjs(
                        this.selectedDate + 'T' + this.time?.start_time
                     ).format('HH:mm'),
                  },
               }
            );
            if (data?.value) {
               this.busy = false;
               this.$emit('success', 'upcoming');
               this.setSnackbarDetail({
                  type: 'message',
                  title: data?.value?.message,
                  show: true,
               });
            }
            if (error?.value) {
               this.busy = false;
               this.error = error?.value?.data?.message;
               this.setSnackbarDetail({
                  type: 'error',
                  title: error?.value?.data?.message,
                  show: true,
               });
            }
         },
         async addNewInterval() {
            this.error = null;
            let dataToPost = {
               availability_state: this.selectedOption === 'available' ? 1 : 0,
               date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
               start_time: this.time?.start_time,
               end_time: this.time?.end_time,
            };
            if (this.loading) return;
            this.loading = true;
            let { data, pending, error } = await useTherapistApi(
               apiRoutes?.therapist?.customAvailability?.post,
               {
                  method: 'POST',
                  body: dataToPost,
               }
            );
            if (data?.value) {
               this.loading = pending.value;
               this.setReloadValue(true);
               this.$emit('success');
               this.setSnackbarDetail({
                  show: true,
                  title: 'Your custom availability is successfully added. Open Availability Custom tab to view it',
                  type: 'message',
               });
               this.$emit('close', {
                  date: this.selectedDate,
                  time: this.time,
               });
            }
            if (error?.value) {
               this.error = error?.value?.data?.message;
               this.loading = pending.value;
            }
         },
      },
   };
</script>
