export const useTherapistAvailabilityStore = defineStore(
   'therapistAvailabilityStore',
   () => {
      const selectedDate: Ref<string | null> = ref(null);
      const updateSelectedDate = (date: string | null) => {
         selectedDate.value = date;
      };
      return {
         selectedDate,
         updateSelectedDate,
      };
   }
);
